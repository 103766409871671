import React from "react";
import ProductTemplate from "./product";
// import { FiArrowRightCircle } from "react-icons/fi";
//import Image from "@components/common/CloudinaryImage";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
//import { Link } from "gatsby";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            <div className="container lg:px-0 py-20">
                {/** Qué es¿? */}
                <Title>Curs Intensiu per Mossos d'Esquadra 2022-2023</Title>
                <Text>
                    L'únic curs presencial que et dona accés a un campus online fins a la data
                    d'examen.
                    <br />
                    <br />
                    Les classes presencials s'inicien el 5 de novembre , ara pots matricular-te i
                    accedir al campus online.
                    <br />
                    <br />
                    Al campus trobaràs temari, exercicis, actualitat i psicotècnics.
                    <br />
                    <br />
                    Aprofita tot l'estiu per estudiar amb el nostre campus i agafa ritme.
                    <br />
                    <br />
                    El curs presencial són 25 hores de tot el temari exigit a les bases de la
                    convocatòria
                    <br />
                    <br />
                    El nostre mètode és diferent de la resta, ja que ens centrem en els continguts
                    que realment són importants i successibles de sortir a l'examen.
                    <br />
                    <br />
                    Tots els alumnes comencen i finalitzen junts el curs, d'aquesta manera
                    assoleixen al màxim tots els continguts.
                    <br />
                    <br />
                    El curs es realitza tots els dissabtes
                    <br />
                    <br />
                    Inici del curs dissabte 5 de novembre de 15,00 a 20.00 h
                    <br />
                    <br />
                    19 de novembre
                    <br />
                    <br />
                    26 de novembre
                    <br />
                    <br />
                    3 de desembre
                    <br />
                    <br />
                    10 de desembre Fi de curs.
                    <br />
                    <br />
                    El primer dia de classe us regalarem la nostra guia de Mossos d'Esquadra
                    2022-23, esquematitzada i molt amena d'estudiar.
                    <br />
                    <br />
                    El curs s'imparteix a Hospitalet de Llobregat.
                    <br />
                    <br />
                    Al c/ Martí Codolar núm. 18
                    <br />
                    <br />
                    El preu de tot el curs és 159,99 euros
                    <br />
                    <br />
                    T'esperem!
                </Text>
            </div>
        </ProductTemplate>
    );
};
export default Product;
